import React, { useContext, useState } from 'react';
import { ArrowDownIcon, DeleteIcon } from '../atoms/Icons';
import { deleteItemHistoryChat, getPaginateChat } from 'services/chat/chat';
import { useQuery, useMutation } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MessageContext } from 'contexts/GlobalStateMessage';
import { checkRoleAuth } from 'utils/authUtils';

const CardItemHistoryChat = () => {
  const role = checkRoleAuth();
  const navigate = useNavigate();
  const { chatId } = useParams();
  const [maxHeight, setMaxHeight] = useState(20);
  const [hoveredItem, setHoveredItem] = useState(null);
  const { loadingMessage } = useContext(MessageContext);

  const { data: dataPaginateChats, refetch } = useQuery(
    ['fetchPaginationChats'],
    () => getPaginateChat(),
    {
      enabled: true,
    }
  );

  const itemHistoryChatRecent =
    dataPaginateChats?.data?.recent_chats?.map((item) => ({
      id: item?.id,
      label: item?.name,
      path: `chatbot/${item?.id}`,
      active: chatId == item?.id,
    })) || [];

  const itemHistoryChatOld =
    dataPaginateChats?.data?.old_chats?.map((item) => ({
      id: item?.id,
      label: item?.name,
      path: `chatbot/${item?.id}`,
      active: chatId == item?.id,
    })) || [];

  const mutation = useMutation((chatId) => deleteItemHistoryChat(chatId), {
    onSuccess: (data) => {
      toast.success(data?.message);
      refetch();
    },
    onError: (error) => {
      toast.error(error);
      refetch();
    },
  });

  const DeleteItemHistoryChat = (chatId) => {
    mutation.mutate(chatId);
  };

  return (
    <>
      <div
        className={`mt-5 overflow-y-auto`}
        style={{ maxHeight: `${maxHeight}rem` }}
      >
        <div className="pl-6">
          {dataPaginateChats?.data?.recent_chats && (
            <span className="font-normal text-sm text-[#98A2B3]">Recent</span>
          )}
        </div>
        {itemHistoryChatRecent?.map((child) => (
          <div
            key={child.path}
            className={`pl-0 flex items-center my-6 px-4 ml-4`}
          >
            <div
              className={`w-full flex flex-wrap item-center font-normal text-sm ${child.active ? 'text-[#5D42BD] bg-[#EFF1FE] py-2 px-2 rounded-lg' : !child.active && hoveredItem === child.path ? 'text-[#5D42BD] ml-2' : 'text-[#344054] ml-2'} '}
            `}
              onMouseEnter={() => !child.active && setHoveredItem(child.path)}
              onMouseLeave={() => !child.active && setHoveredItem(null)}
            >
              <div
                onClick={
                  !loadingMessage
                    ? () => navigate(child.path)
                    : function noRefCheck() {}
                }
                className={`${!child.active && hoveredItem === child.path && 'w-4/5'} ${!loadingMessage && 'cursor-pointer'}`}
              >
                {!child.active && hoveredItem === child.path
                  ? child.label.length > 15
                    ? child.label.substring(0, 15) + '...'
                    : child.label
                  : child.label.length > 20
                    ? child.label.substring(0, 20) + '...'
                    : child.label}
              </div>
              <div
                className={`w-auto flex flex-wrap items-center pt-1 pl-1 ml-2 ${!loadingMessage && 'cursor-pointer'}`}
              >
                {!child.active && hoveredItem === child.path && (
                  <DeleteIcon
                    width="16"
                    height="16"
                    color="#5D42BD"
                    onClick={
                      !loadingMessage
                        ? () => DeleteItemHistoryChat(child?.id)
                        : function noRefCheck() {}
                    }
                  />
                )}
              </div>
            </div>
          </div>
        ))}
        <div className="pl-6">
          {dataPaginateChats?.data?.old_chats && (
            <span className="font-normal text-sm text-[#98A2B3]">Old</span>
          )}
        </div>
        {itemHistoryChatOld.map((child) => (
          <div
            key={child.path}
            className={`pl-0 flex items-center my-6  px-4 ml-4`}
          >
            <div
              className={`w-full flex flex-wrap item-center font-normal text-sm ${child.active ? 'text-[#5D42BD] bg-[#EFF1FE] py-2 px-2 rounded-lg' : !child.active && hoveredItem === child.path ? 'text-[#5D42BD] ml-2' : 'text-[#344054] ml-2'} '}
            `}
              onMouseEnter={() => !child.active && setHoveredItem(child.path)}
              onMouseLeave={() => !child.active && setHoveredItem(null)}
            >
              <div
                onClick={
                  !loadingMessage
                    ? () => navigate(child.path)
                    : function noRefCheck() {}
                }
                className={`${!child.active && hoveredItem === child.path && 'w-4/5'} ${!loadingMessage && 'cursor-pointer'}`}
              >
                {!child.active && hoveredItem === child.path
                  ? child.label.length > 15
                    ? child.label.substring(0, 15) + '...'
                    : child.label
                  : child.label.length > 20
                    ? child.label.substring(0, 20) + '...'
                    : child.label}
              </div>
              <div
                className={`w-auto flex flex-wrap items-center pt-1 pl-1 ml-2 ${!loadingMessage && 'cursor-pointer'}`}
              >
                {!child.active && hoveredItem === child.path && (
                  <DeleteIcon
                    width="16"
                    height="16"
                    color="#5D42BD"
                    onClick={
                      !loadingMessage
                        ? () => DeleteItemHistoryChat(child?.id)
                        : function noRefCheck() {}
                    }
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center">
        {(dataPaginateChats?.data?.recent_chats ||
          dataPaginateChats?.data?.old_chats) && (
          <div
            className="w-auto flex flex-wrap items-center gap-x-2 cursor-pointer"
            onClick={() => setMaxHeight(role === 'ADMIN' ? 25 : 30)}
          >
            <span className="font-semibold text-sm">See more</span>{' '}
            <ArrowDownIcon />
          </div>
        )}
      </div>
    </>
  );
};

export default CardItemHistoryChat;
